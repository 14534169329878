<template>
  <div class="mt-3">
    <table
      class="table table-striped"
      :class="[{ 'table-dark': false }, 'table-bordered']"
    >
      <!-- Table Headers  -->
      <thead class="thead-light">
        <th>Site ID</th>
        <th>Organization Name</th>
        <th>Client Data</th>
        <th>CollectionID</th>
        <th>Site Link</th>
        <th>Login Link</th>
        <th v-if="isInternalAdmin()">Settings Link</th>
        <th v-if="isInternalAdmin()">Activate Site</th>
        <th v-if="isInternalAdmin()">Delete</th>
        <th>
          <Dropdown
            text="Activation Date"
            :options="activationDateOptions"
            @setSortingData="setSortingData"
          />
        </th>
        <th>
          <Dropdown
            text="Last Login"
            :options="lastLoginOptions"
            @setSortingData="setSortingData"
          />
        </th>
        <th>
          <Dropdown
            text="Last Touchscreen Heartbeat"
            :options="lastTouchscreenHeartbeatOptions"
            @setSortingData="setSortingData"
          />
        </th>
        <th>
          <Dropdown
            text="Site Fullscreen"
            :options="siteFullscreenOptions"
            @setSortingData="setSortingData"
          />
        </th>
        <th>
          <Dropdown
            text="Document Count"
            :options="documentCountOptions"
            @setSortingData="setSortingData"
          />
        </th>
        <th>
          <Dropdown
            text="Owner"
            :options="siteOwnerOptions"
            @setSortingData="setSortingData"
          />
        </th>
        <th>
          <Dropdown
            text="Site Rating"
            :options="siteRatingOptions"
            @setSortingData="setSortingData"
          />
        </th>
        <th>
          <Dropdown
            text="Needs Attention"
            :options="needsAttentionOptions"
            @setSortingData="setSortingData"
          />
        </th>
      </thead>
      <!-- Table content  -->
      <tbody v-cloak>
        <tr v-for="a in getSites" :key="a.siteID" :name="a.styleName">
          <td>{{ a.siteID }}</td>
          <td>
            <input
              v-on:keyup.enter="changeName(a.siteID, a.styleName)"
              class="edit"
              type="text"
              v-model="a.styleName"
            />
          </td>
          <td>
            <div v-if="!isInternalAdmin()">
              {{
                getSelectedClientID(getClientCollectionIDs, a.clientID)
                  ? getSelectedClientID(getClientCollectionIDs, a.clientID).name
                  : "unknown"
              }}
            </div>
            <!-- Only Show Dropdown If Internal Admin -->
            <b-dropdown
              v-else
              :text="
                getSelectedClientID(getClientCollectionIDs, a.clientID)
                  ? getSelectedClientID(getClientCollectionIDs, a.clientID).name
                  : 'unknown'
              "
              block
              variant="transparent"
              class="m-2"
              :lazy="true"
            >
              <b-dropdown-item-button
                v-for="(object, index) in getClientCollectionIDs"
                :key="index"
                @click.prevent="changeData(a.siteID, object.id)"
              >
                {{ object.name }} : {{ object.id }}
              </b-dropdown-item-button>
            </b-dropdown>
          </td>
          <td>
            {{ a.clientID }}
          </td>
          <td>
            <button
              class="btn btn-warning"
              @click="goToEvent(a.siteID, 'home')"
            >
              View
            </button>
          </td>
          <td>
            <button
              class="btn btn-warning"
              @click="goToEvent(a.siteID, 'login')"
            >
              Login
            </button>
          </td>
          <td v-if="isInternalAdmin()">
            <button
              class="btn btn-warning"
              @click="goToEvent(a.siteID, 'settings')"
            >
              Settings
            </button>
          </td>
          <td v-if="isInternalAdmin()">
            <div v-if="!a.isActivated">
              <router-link :to="'/activateSite/' + a.siteID">
                <b-button
                  style="display: flex; background-color: white; color: black"
                >
                  Activate Site</b-button
                >
              </router-link>
            </div>
            <div v-else>
              <b-button
                style="background-color: #f08080; border-color: #f08080"
                @click="deactivateSite(a.siteID)"
              >
                Deactivate Site</b-button
              >
            </div>
          </td>
          <td v-if="isInternalAdmin()">
            <button
              style="background-color: #f08080; border-color: #f08080"
              class="btn btn-warning"
              v-if="!a.isActivated"
              @click="remove(a.siteID)"
            >
              Delete
            </button>
            <div v-else>🚀</div>
          </td>
          <td>{{ formatTimestamp(a, "activationDate") }}</td>
          <td>{{ formatTimestamp(a, "lastLoginTime") }}</td>
          <td>{{ formatLastTouchscreenHeartbeatTimestamp(a) }}</td>
          <td>{{ formatLastTouchscreenHeartbeatFullscreen(a) }}</td>

          <td>{{ a.documentCount }}</td>
          <td>
            <b-dropdown
              :text="a.csDetails.siteOwner || 'Set Site Owner'"
              block
              variant="transparent"
              class="m-2"
              :lazy="true"
            >
              <b-dropdown-item-button
                v-for="(person, index) in customerSuccessManagers"
                :key="index"
                @click.prevent="changeOwner(a.siteID, a.csDetails, person)"
              >
                {{ person }}
              </b-dropdown-item-button>
            </b-dropdown>
          </td>
          <td
            @mouseover="showDetails = true"
            @mouseleave="showDetails = false"
            class="rating-cell"
          >
            {{ siteRating(a).emojiString }}
            <SiteRatingDetails
              class="rating-tooltip"
              v-if="showDetails"
              :breakdown="siteRating(a).breakdown"
            />
          </td>
          <td>
            <input
              type="checkbox"
              v-model="a.csDetails.csAlert"
              @change="
                changeCsAlert(a.siteID, a.csDetails, a.csDetails.csAlert)
              "
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import Dropdown from "@/components/GridHeaderDropdown";
import SiteRatingDetails from "@/components/SiteRatingDetails.vue";
import filteredSitesMixin from "@/mixins/filteredSites";
import updateSiteInfoMixin from "@/mixins/updateSiteInfo.js";
import formatTimestampMixin from "@/mixins/formatTimestamp";
import siteRatingMixin from "@/mixins/siteRating";
import sortSitesMixin from "@/mixins/sortSites";
import tableToCsvExportMixin from "@/mixins/tableToCsvExport";
import {
  PAGE_SIZE,
  customerSuccessManagers,
  activationDateOptions,
  lastLoginOptions,
  lastTouchscreenHeartbeatOptions,
  siteFullscreenOptions,
  documentCountOptions,
  siteOwnerOptions,
  siteRatingOptions,
  needsAttentionOptions,
} from "@/utils/constants";

export default {
  name: "Grid",
  components: {
    Dropdown,
    SiteRatingDetails,
  },
  mixins: [
    filteredSitesMixin,
    updateSiteInfoMixin,
    siteRatingMixin,
    formatTimestampMixin,
    sortSitesMixin,
    tableToCsvExportMixin,
  ],
  data: function () {
    return {
      disabled: false,
      variable: "",
      edit: false,
      id: "",
      message: "",
      keyToSortBy: "",
      sortingOrder: "",
      showDetails: false,
      customerSuccessManagers,
      activationDateOptions,
      lastLoginOptions,
      lastTouchscreenHeartbeatOptions,
      siteFullscreenOptions,
      documentCountOptions,
      siteOwnerOptions,
      siteRatingOptions,
      needsAttentionOptions,
    };
  },
  props: {
    filterByActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getClientCollectionIDs() {
      return this.$store.getters.getClientCollectionIDs;
    },
    getSites() {
      const currPage = this.$store.getters.getCurrPage;
      const start = currPage * PAGE_SIZE;
      const end = (currPage + 1) * PAGE_SIZE;
      let filteredSites = this.sortSites();
      const sitesInView = filteredSites.slice(start, end);
      return sitesInView;
    },
  },
  methods: {
    isInternalAdmin() {
      return this.$auth.getIsSiteAdmin();
    },
    formatLastTouchscreenHeartbeatFullscreen(a) {
      if (
        a.lastTouchscreenHeartbeat &&
        typeof a.lastTouchscreenHeartbeat === "object"
      ) {
        return a.lastTouchscreenHeartbeat.fullscreen ? "✅" : "❌";
      }
      return "N/A";
    },
    // function to get the selected clientID info
    getSelectedClientID(list, clientID) {
      const index = list.findIndex((e) => {
        return e.id == clientID;
      });
      return list[index];
    },
    /* Function to set the active dropdown element to true */
    isActive(styleID, clientID) {
      if (styleID == clientID) {
        return true;
      }
      return false;
    },
    /* Link to CMS and Home page for site */
    goToEvent(styleID, location) {
      switch (location) {
        case "home":
          window.open(`${process.env.VUE_APP_APP_ROOT}/home/${styleID}`);
          break;
        case "login":
          window.open(`${process.env.VUE_APP_APP_ROOT}/login/${styleID}`);
          break;
        case "settings":
          window.open(`${process.env.VUE_APP_APP_ROOT}/settings/${styleID}`);
          break;
      }
    },
    /* Changes the client collection (data) of a site */
    changeData(siteID, clientID) {
      this.updateSiteInfo(siteID, "data", { clientID });
    },
    changeOwner(siteID, csDetails, siteOwner) {
      const newDetails = {
        ...csDetails,
        siteOwner,
      };
      this.updateSiteInfo(siteID, "Owner", { csDetails: newDetails });
    },
    /* Changes the name of a site */
    changeName(siteID, styleName) {
      this.updateSiteInfo(siteID, "Organization Name", { styleName });
    },
    changeCsAlert(siteID, csDetails, csAlert) {
      const newDetails = {
        ...csDetails,
        csAlert,
      };
      this.updateSiteInfo(siteID, "Needs Attention", { csDetails: newDetails });
    },
    /*  Delete a site */
    remove(siteID) {
      const confirmed = window.confirm(
        `Are you sure you want to DELETE site ${siteID}?`
      );
      if (confirmed) {
        this.$api
          .delete(`/siteID/${siteID}/`)
          .then(() => {
            alert(`Site ${siteID} has been deleted.`);
            this.$emit("refetch");
          })
          .catch(() => {
            alert(`Could not delete site ${siteID}. Please contact support.`);
          });
      }
    },
    deactivateSite(siteID) {
      const confirmed = window.confirm(
        `Are you sure you want to DEACTIVATE site ${siteID}?`
      );
      if (confirmed)
        this.updateSiteInfo(siteID, "isActivated", { isActivated: false });
    },
    setSortingData(keyToSortBy, sortingOrder) {
      this.keyToSortBy = keyToSortBy;
      this.sortingOrder = sortingOrder;
    },
  },
};
</script>

<style scoped>
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

td {
  text-align: center;
  vertical-align: middle;
}
th:first-child,
td:first-child {
  position: sticky;
  left: 0;
  background: #e9ecef;
  z-index: 100;
}
th:nth-child(2),
td:nth-child(2) {
  position: sticky;
  left: 205px;
  background: #e9ecef;
  z-index: 100;
}

.active {
  background-color: #c0c0c0;
}
.active.dropdown-item {
  color: white !important;
}
/* ---------------------------------------------------
    Grid styling
----------------------------------------------------- */
.btn.btn-warning {
  background-color: #133353;
  color: #fff;
  border-color: #133353;
  font-weight: bold;
}
.btn.btn-warning:hover {
  background-color: white;
  color: #133353;
  border-color: gray;
}
.btn .btn-warning .active {
  background-color: red;
}
.input.edit {
  border: none !important;
  background: none !important;
}

.edit {
  border: none !important;
  background: none !important;
}

.rating-cell {
  position: relative;
}

.rating-tooltip {
  position: absolute;
  transform: translateX(-70%);
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 10;
  width: 170px;
}

.rating-cell:hover .rating-tooltip {
  display: block;
}
</style>
